<template>
  <div class="textbox">
    <div class="bottombox">
      <div class="bottom_left">
        <div class="tabbox">
          <div class="tabtitle">消息通知</div>

          <div
            class="tabbtn"
            :class="i == 1 || i == 2 ? 'active' : ''"
            @click="gettags(1)"
          >
            - 消息列表
          </div>
        </div>
      </div>
      <!-- 个人信息 -->
      <div class="bottom_right" v-if="i == 1">
        <!-- <div class="huanying">欢迎您：管理员</div> -->
        <div class="smalltitle">消息列表</div>
        <div class="userinforbox">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column label="联系人">
              <template slot-scope="scope">
                <span>{{ scope.row.ReceiverName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="日期">
              <template slot-scope="scope">
                <i class="el-icon-time"></i>
                <span style="margin-left: 10px">{{ scope.row.ModifyOn }}</span>
              </template>
            </el-table-column>
            <el-table-column label="消息">
              <template slot-scope="scope">
                <span class="newsinfor"
                  >{{ scope.row.Info }}
                  <i v-if="scope.row.NoReadCount">{{
                    scope.row.NoReadCount
                  }}</i></span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button size="mini" @click="handleEdit(scope.row)"
                  >查看</el-button
                >
                <!-- <el-button
          size="mini"
          type="danger"
          @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
              </template>
            </el-table-column>
          </el-table>

          <div class="page">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="currentSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totoPage"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <div class="bottom_right" v-if="i == 2">
        <div class="msgbox" v-drag>
          <div class="topbox">
            <!-- <img src="../assets/img/girl.png" alt="" /> -->
            <span>{{ item.ReceiverName }}</span>
            <div class="cloese" @click="cloeseChat">
              <i class="el-icon-close"></i>
            </div>
          </div>
          <div class="textboxs" id="new_message">
            <div v-for="(item, i) in mewsList" :key="i">
              <div class="othernew" v-if="!item.IsMine">
                <div class="namebox">
                  {{ item.CreateByName }} <span>{{ item.time }}</span>
                </div>
                <div class="nexsbox">
                  <span>{{ item.Info }}</span>
                </div>
              </div>

              <div class="othernew" v-if="item.IsMine">
                <div class="minebox">
                  {{ item.CreateByName }} <span>{{ item.time }}</span>
                </div>
                <div class="newsbox">
                  <span>{{ item.Info }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="inputbox">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入内容"
              v-model="textarea"
            >
            </el-input>

            <div class="sendbox">
              <span @click="seedmsg">发送</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 公共样式
import timeFormat from "../util/time.js";
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("年龄不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (value < 18) {
            callback(new Error("必须年满18岁"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      tableData: [],
      mewsList: [],
      textarea: "",
      index: 1,
      i: 1,
      dialogVisible: false,
      ruleForms: {
        pass: "",
        checkPass: "",
        age: "",
      },
      ruless: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        age: [{ validator: checkAge, trigger: "blur" }],
      },
      ruleForm: {
        dialogImageUrl: "",
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 2, max: 50, message: "2 到 50 个字符", trigger: "blur" },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
      totoPage: null,
      currentPage: 1,
      currentSize: 5,
      compObj: {},
      getmsgtime: null,
      isCarre: "",
    };
  },

  methods: {
    // 分页
    handleSizeChange(a) {
      console.log(a);
      this.currentSize = a;
      this.getnewsList();
    },
    handleCurrentChange(b) {
      console.log(b);
      this.currentPage = b;
      this.getnewsList();
    },
    handleDragClick(e) {
      // 判断拖拽组件的状态
      let isDrag = false;
      try {
        isDrag = e.target.getAttribute("ele-flag") === "true";
      } catch (e) {}
      if (isDrag) {
        return;
      }
    },

    handleEdit(item) {
      console.log(item);
      this.item = item;
      this.getnews();
      this.ischat = true;

      this.getmsgtime = setInterval(() => {
        this.getEachMessage();
      }, 3000);
      this.i = 2;
      this.scrollToBottom();
    },
    getnews() {
      let that = this;
      let UserID = window.localStorage.getItem("userId");
      let item = that.item;

      let UserIDKey = item.UserIDKey;
      that
        .$post("/api/IMMessage/MessageList", {
          UserID: UserID,
          UserIDKey,
          IsClick: 1,
        })
        .then(function (res) {
          console.log(res);
          that.mewsList = res.Obj;
          that.scrollToBottom();
        });
    },

    cloeseChat() {
      this.i = 1;
      this.item = {};
      this.mewsList = [];
      this.getnewsList();
      clearInterval(this.getmsgtime);
    },
    scrollToBottom: function () {
      this.$nextTick(() => {
        var container = this.$el.querySelector("#new_message");
        container.scrollTop = container.scrollHeight;
      });
    },
    // 发送消息
    seedmsg() {
      let that = this;
      let item = that.item;
      let text = that.textarea;
      if (!text.trim()) {
        return;
      }
      let CreateBy = item.Receiver;
      let SysID = item.SysID;
      let CompanySysID = item.CompanySysID;
      that
        .$post("/api/IMMessage/Add", {
          Receiver: CreateBy,
          CompanySysID,
          Info: text,
          SourceSysID: SysID,
          Type: 2,
        })
        .then(function (res) {
          console.log(res);
          that.mewsList.push(res.Obj);
          that.textarea = "";
          that.scrollToBottom();
        });
    },
    getindex() {
      window.localStorage.setItem("index", "1");
      this.$router.push("/");
    },
    gettags(i) {
      this.i = i;
    },

    getcompany() {
      this.$router.push("/centerHome");
    },
    getperson() {
      this.$router.push("/personalCenter");
    },
    getcarrier() {
      this.$router.push("/carrierCenter");
    },
    getnewsList() {
      let that = this;
      let userid = window.localStorage.getItem("userId");
      that
        .$post("/api/IMMessage/List", {
          UserID: userid,
          PageIndex: that.currentPage,
          PageSize: that.currentSize,
        })
        .then(function (data) {
          console.log(data);

          let company = data.Obj;
          company.forEach((v) => {
            v.ModifyOn = timeFormat.timeFormat(v.ModifyOn, 2);
          });

          that.tableData = company;
          that.totoPage = data.RecordCount;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取个人信息
    getinfor() {
      let that = this;
      let Account = window.localStorage.getItem("Account");
      that
        .$post("/api/UserInfo/ObjAccount", {
          Account: Account,
        })
        .then(function (data) {
          console.log(data);
          let company = data.Obj.UserObj;
          that.isCarre = data.Obj.BuildingObj.SysID;

          that.compObj = company;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEachMessage() {
      let that = this;
      let UserID = window.localStorage.getItem("userId");
      let item = that.item;
      let ida = item.CreateBy;
      let UserIDKey = ida + "|" + UserID;
      that
        .$post("/api/IMMessage/EachList", {
          UserID: UserID,
          UserIDKey,
        })
        .then(function (res) {
          console.log(res);
          let meslist = res.Obj;
          if (!meslist.length) {
            return;
          }
          meslist.forEach((element) => {
            that.mewsList.push(element);
          });

          that.scrollToBottom();
        });
    },
  },

  mounted() {
    this.getnewsList();
    this.getinfor();
  },
  beforeDestroy() {
    clearInterval(this.getmsgtime);
  },
};
</script>


<style scoped  lang="scss">
.msgbox {
  //   height: 550px;
  position: fixed;
  width: 520px;

  // background-color: aqua;
  //  margin: 0 auto;
  border: 1px solid #dee0e3;
  // margin-bottom: 10px;
  background-color: #fff;
  z-index: 99999;
  overflow: hidden;
  .topbox {
    position: relative;
    margin-top: 0;
    height: 80px;
    background-color: rgb(161, 212, 253);
    overflow: hidden;
    cursor: move;
    img {
      float: left;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      margin-top: 10px;
      margin-left: 10px;
    }
    span {
      float: left;
      height: 80px;
      line-height: 80px;
      font-weight: 600;
      font-size: 16px;
      margin-left: 10px;
      color: #222;
    }
    .cloese {
      position: absolute;
      cursor: pointer;
      right: 10px;
      top: 10px;
      font-size: 20px;
      color: #fff;
    }
  }
  .textboxs {
    position: relative;
    height: 340px;
    overflow: auto;
    z-index: 9999999;
    .othernew {
      // background: blueviolet;
      padding: 6px 10px;
      box-sizing: border-box;
      .namebox {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        width: 460px;
        margin-left: 50%;
        transform: translateX(-50%);
        span {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          font-size: 12px;
          color: #888;
        }
      }

      .minebox {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        text-align: right;
        width: 460px;
        margin-left: 50%;
        transform: translateX(-50%);
        span {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          font-size: 12px;
          color: #888;
        }
      }
    }
  }

  .nexsbox {
    width: 460px;
    margin-left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 3px;
    span {
      float: left;
      background-color: #dee0e3;
      line-height: 26px;
      padding: 6px 10px;
      border-radius: 3px;
    }
  }
  .newsbox {
    width: 460px;
    margin-left: 50%;
    transform: translateX(-50%);
    overflow: hidden;

    font-size: 14px;

    box-sizing: border-box;

    span {
      float: right;
      background-color: #cce4fc;
      line-height: 26px;
      padding: 6px 10px;
      border-radius: 3px;
    }
  }
  .inputbox {
    //   height: 160px;
    //   background-color: blueviolet;
    border-top: 1px solid #eee;
    ::v-deep .el-textarea__inner {
      border: none;
      border-radius: 0;
      resize: none;
      height: 90px;
      line-height: 30px;
    }
  }
  .sendbox {
    // height: 40px;
    overflow: hidden;
    // background-color: brown;

    span {
      float: right;
      padding: 3px 20px;
      background-color: #008cff;
      border-radius: 3px;
      font-size: 14px;
      color: #fff;
      margin-right: 20px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}

//表格
.newsinfor {
  position: relative;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 10px;
  i {
    position: absolute;
    top: 0;
    right: 0;
    height: 15px;
    line-height: 15px;
    width: 15px;
    background-color: red;
    color: #fff;
    font-size: 6px;
    text-align: center;
    border-radius: 50%;
    font-style: normal;
  }
}
.page {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.textbox {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f6f6f6;
}
.topbox {
  width: 100%;
  background-color: #fff;
  height: 100px;
  margin-top: 30px;
}
.topboxinfor {
  width: 1200px;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: aquamarine;
}
.topboxinfor img {
  float: left;
  height: 60px;
  margin-top: 20px;
  margin-right: 40px;
}

.bottombox {
  width: 1200px;
  min-height: 600px;
  margin-left: 50%;
  margin-top: 20px;
  transform: translateX(-50%);
  // background-color: aquamarine;
  overflow: hidden;
}
.bottom_left {
  float: left;
  // background-color: red;
  width: 300px;
  min-height: 600px;
}
.bottom_right {
  float: left;
  width: 880px;
  margin-left: 20px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #eee;
  min-height: 600px;
  box-sizing: border-box;
}
.tabbox {
  width: 100%;
  border: 1px solid #eee;
  background-color: #fff;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.tabtitle {
  padding-left: 30px;
  box-sizing: border-box;
  height: 50px;
  line-height: 55px;
  font-size: 16px;
}
.tabbtn {
  height: 35px;
  line-height: 35px;
  padding-left: 40px;
  box-sizing: border-box;
  font-size: 14px;
  color: #555;
  cursor: pointer;
}
.active {
  border-left: 3px solid blue;
  background-color: #f6f6f6;
}
.active1 {
  background-color: #e9e9e9;
}
.huanying {
  height: 60px;
  line-height: 30px;
  padding: 15px;
  width: 100%;
  background-color: #d9edf7;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
}
.smalltitle {
  height: 36px;
  line-height: 6px;
  font-size: 14px;
  background-color: #f1f1f1;
  margin-top: 15px;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.userinforbox {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}
.username {
  height: 80px;
  line-height: 80px;
  font-size: 14px;
  span {
    display: inline-block;
    width: 100px;
    color: #666;
  }
}
.userlogo {
  height: 100px;

  line-height: 100px;
  font-size: 14px;
  span {
    display: inline-block;
    width: 100px;
    color: #666;
  }
  img {
    height: 80px;
    width: 80px;
    vertical-align: middle;
  }
}
.inforedint {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #f1f1f1;
  span {
    cursor: pointer;
    background-color: #1890ff;
    padding: 3px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

.password {
  display: flex;
  align-items: center;
  // height: 80px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.left {
  flex: 6;
  .left_title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 15px;
    box-sizing: border-box;
    color: #555;
  }
  .left_text {
    color: #555;
    line-height: 30px;
    font-size: 14px;
    padding-left: 15px;
    box-sizing: border-box;
  }
}
.right {
  flex: 1;
  display: flex;
  justify-content: center;
  span {
    background-color: #1890ff;
    cursor: pointer;
    padding: 3px 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 3px;
  }
}

@media (max-width: 500px) {
  .msgbox {
    //   height: 550px;
    position: fixed;
    width: 100vw;

    // background-color: aqua;
    //  margin: 0 auto;
    border: 1px solid #dee0e3;
    // margin-bottom: 10px;
    background-color: #fff;
    z-index: 99999;
    overflow: hidden;
    .topbox {
      position: relative;
      margin-top: 0;
      height: 10vh;
      background-color: rgb(161, 212, 253);
      overflow: hidden;
      cursor: move;
      img {
        float: left;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-top: 10px;
        margin-left: 10px;
      }
      span {
        float: left;
        height: 40px;
        line-height: 40px;
        font-weight: 600;
        font-size: 16px;
        margin-left: 10px;
        max-width: 300px;
        color: #222;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .cloese {
        position: absolute;
        cursor: pointer;
        right: 10px;
        top: 10px;
        font-size: 20px;
        color: #fff;
      }
    }
    .textboxs {
      position: relative;
      padding: 0 10px;
      box-sizing: border-box;
      height: 300px;
      overflow: auto;
      z-index: 9999999;
      .othernew {
        // background: blueviolet;
        padding: 6px 10px;
        box-sizing: border-box;
        .namebox {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          width: 100%;
          margin-left: 50%;
          transform: translateX(-50%);
          span {
            display: inline-block;
            height: 30px;
            line-height: 30px;
            font-size: 12px;
            color: #888;
          }
        }

        .minebox {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          text-align: right;
          width: 100%;
          margin-left: 50%;
          transform: translateX(-50%);
          span {
            display: inline-block;
            height: 30px;
            line-height: 30px;
            font-size: 12px;
            color: #888;
          }
        }
      }
    }

    .nexsbox {
      width: 100%;
      margin-left: 50%;
      transform: translateX(-50%);
      overflow: hidden;

      font-size: 14px;

      box-sizing: border-box;
      border-radius: 3px;
      span {
        float: left;
        background-color: #dee0e3;
        line-height: 26px;
        padding: 6px 10px;
        border-radius: 3px;
      }
    }
    .newsbox {
      width: 100%;
      margin-left: 50%;
      transform: translateX(-50%);
      overflow: hidden;

      font-size: 14px;

      box-sizing: border-box;

      span {
        float: right;
        background-color: #cce4fc;
        line-height: 26px;
        padding: 6px 10px;
        border-radius: 3px;
      }
    }
    .inputbox {
      //   height: 160px;
      //   background-color: blueviolet;
      border-top: 1px solid #eee;
      ::v-deep .el-textarea__inner {
        border: none;
        border-radius: 0;
        resize: none;
        height: 90px;
        line-height: 30px;
      }
    }
    .sendbox {
      // height: 40px;
      overflow: hidden;
      // background-color: brown;

      span {
        float: right;
        padding: 3px 20px;
        background-color: #008cff;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        margin-right: 20px;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }

  //表格
  .newsinfor {
    position: relative;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    i {
      position: absolute;
      height: 15px;
      line-height: 15px;
      width: 15px;
      background-color: red;
      color: #fff;
      font-size: 6px;
      text-align: center;
      border-radius: 50%;
      font-style: normal;
    }
  }
  .page {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .textbox {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
    background-color: #f6f6f6;
  }
  .topbox {
    width: 100%;
    background-color: #fff;
    height: 100px;
    margin-top: 30px;
  }
  .topboxinfor {
    width: 1200px;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
    // background-color: aquamarine;
  }
  .topboxinfor img {
    float: left;
    height: 60px;
    margin-top: 20px;
    margin-right: 40px;
  }

  .bottombox {
    width: 500px;
    min-height: 100px;
    margin-left: 0;
    margin-top: 20px;
    transform: translateX(0);
    // background-color: aquamarine;
    overflow: auto;
  }
  .bottom_left {
    display: none;
    float: left;
    // background-color: red;
    width: 100%;
    min-height: 60px;
  }
  .bottom_right {
    float: left;
    width: 100%;
    margin-left: 0;
    padding: 1px;
    background-color: #fff;
    border: 1px solid #eee;
    min-height: 600px;
    box-sizing: border-box;
  }
  .tabbox {
    width: 100%;
    border: 1px solid #eee;
    background-color: #fff;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
  .tabtitle {
    padding-left: 30px;
    box-sizing: border-box;
    height: 50px;
    line-height: 55px;
    font-size: 16px;
  }
  .tabbtn {
    height: 35px;
    line-height: 35px;
    padding-left: 40px;
    box-sizing: border-box;
    font-size: 14px;
    color: #555;
    cursor: pointer;
  }
  .active {
    border-left: 3px solid blue;
    background-color: #f6f6f6;
  }
  .active1 {
    background-color: #e9e9e9;
  }
  .huanying {
    height: 60px;
    line-height: 30px;
    padding: 15px;
    width: 100%;
    background-color: #d9edf7;
    box-sizing: border-box;
    font-size: 14px;
    color: #666;
  }
  .smalltitle {
    height: 36px;
    line-height: 6px;
    font-size: 14px;
    background-color: #f1f1f1;
    margin-top: 15px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .userinforbox {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }
  .username {
    height: 80px;
    line-height: 80px;
    font-size: 14px;
    span {
      display: inline-block;
      width: 100px;
      color: #666;
    }
  }
  .userlogo {
    height: 100px;

    line-height: 100px;
    font-size: 14px;
    span {
      display: inline-block;
      width: 100px;
      color: #666;
    }
    img {
      height: 80px;
      width: 80px;
      vertical-align: middle;
    }
  }
  .inforedint {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-color: #f1f1f1;
    span {
      cursor: pointer;
      background-color: #1890ff;
      padding: 3px 20px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }

  .password {
    display: flex;
    align-items: center;
    // height: 80px;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
  }
  .left {
    flex: 6;
    .left_title {
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      font-weight: 600;
      padding-left: 15px;
      box-sizing: border-box;
      color: #555;
    }
    .left_text {
      color: #555;
      line-height: 30px;
      font-size: 14px;
      padding-left: 15px;
      box-sizing: border-box;
    }
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: center;
    span {
      background-color: #1890ff;
      cursor: pointer;
      padding: 3px 20px;
      color: #fff;
      font-size: 15px;
      border-radius: 3px;
    }
  }
}
</style>